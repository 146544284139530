/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.blog-tile-component {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 28px 16px;
  position: relative;
  z-index: 1;
}
.blog-tile-component.background-transparent {
  background-color: transparent;
}
.blog-tile-component.background-white {
  background-color: white;
}
.blog-tile-component.background-grey {
  background-color: #ece9e8;
}
@media only screen and (min-width: 901px) {
  .blog-tile-component {
    padding: 44px 40px;
  }
}
@media only screen and (max-width: 359px) {
  .blog-tile-component {
    padding: 30px 12px;
  }
}

.blog-tile-component__title span,
.blog-tile-component__tile-title span {
  display: block;
}

.blog-tile-component__title {
  font-size: 32px;
  line-height: 1.2;
  max-width: 510px;
  margin: 0 auto 32px;
}
@media only screen and (min-width: 901px) {
  .blog-tile-component__title {
    margin-bottom: 37px;
    max-width: none;
  }
}

.blog-tile-component__tile {
  overflow: hidden;
  width: inherit;
}
.blog-tile-component__tile a {
  text-transform: none;
}

.blog-tile-component__tile-content {
  position: relative;
  background-color: #c5c5c5;
}
@media (hover: hover) and (pointer: fine) {
  a .blog-tile-component__tile-content .blog-tile-component__tile-image {
    transition: transform 0.2s ease-out;
  }
  a .blog-tile-component__tile-content:hover .blog-tile-component__tile-image {
    transform: scale(1.02);
  }
}

.blog-tile-component__tile-picture {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: top 0.3s ease;
  width: 100%;
}
.blog-tile-component__tile-picture::after {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-image: linear-gradient(1deg, rgba(0, 0, 0, 0.5) 46.54%, rgba(0, 0, 0, 0) 76.23%);
}

.blog-tile-component__tile-image {
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  width: 100%;
}

.blog-tile-component__tile-text {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  min-height: 228px;
  padding: 20px;
  position: relative;
  max-width: 500px;
}
@media only screen and (min-width: 901px) {
  .blog-tile-component__tile-text {
    min-height: 234px;
  }
}

.blog-tile-component-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  justify-content: space-between;
  width: 100%;
  max-width: 510px;
  margin: auto;
}
@media only screen and (min-width: 901px) {
  .blog-tile-component-wrapper {
    flex-direction: row;
    gap: 0 32px;
    max-width: none;
  }
}

.blog-tile-component__tile-category,
.blog-tile-component__tile-title {
  color: white;
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 130%;
  text-transform: none;
  font-weight: 300;
  margin: 0;
}

.blog-tile-component__tile-title {
  margin-top: 4px;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-tile-component__tile-category {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
}

.blog-tile-component__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}
@media only screen and (min-width: 1025px) {
  .blog-tile-component__button-wrapper {
    margin-top: 40px;
  }
}

.blog-tile-component__button.btn--outlined {
  border-width: 2px;
}
@media only screen and (max-width: 550px) {
  .blog-tile-component__button {
    width: 100%;
  }
}