.blog-tile-component {
    margin: 0 auto;
    max-width: 1200px;
    overflow: hidden;
    padding: 28px 16px;
    position: relative;
    z-index: 1;

    &.background-transparent {
        background-color: transparent;
    }

    &.background-white {
        background-color: white;
    }

    &.background-grey {
        background-color: $wild-sand;
    }

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 40px;
    }

    @include mq($mobile-xs) {
        padding: 30px 12px;
    }
}

.blog-tile-component__title,
.blog-tile-component__tile-title {
    span {
        display: block;
    }
}

.blog-tile-component__title {
    font-size: 32px;
    line-height: 1.2;
    max-width: 510px;
    margin: 0 auto 32px;

    @include mq($not-mobile-tablet-pt) {
        margin-bottom: 37px;
        max-width: none;
    }
}

.blog-tile-component__tile {
    overflow: hidden;
    width: inherit;

    a {
        text-transform: none;
    }
}

.blog-tile-component__tile-content {
    position: relative;
    background-color: $silver;

    a & {

        @include image-hover(".blog-tile-component__tile-image");
    }
}

.blog-tile-component__tile-picture {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 15%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: top 0.3s ease;
    width: 100%;

    &::after {
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background-image:
            linear-gradient(
                1deg,
                rgb(0 0 0 / 50%) 46.54%,
                rgb(0 0 0 / 0%) 76.23%
            );
    }
}

.blog-tile-component__tile-image {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0;
    width: 100%;
}

.blog-tile-component__tile-text {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    min-height: 228px;
    padding: 20px;
    position: relative;
    max-width: 500px;

    @include mq($not-mobile-tablet-pt) {
        min-height: 234px;
    }
}

.blog-tile-component-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    justify-content: space-between;
    width: 100%;
    max-width: 510px;
    margin: auto;

    @include mq($not-mobile-tablet-pt) {
        flex-direction: row;
        gap: 0 32px;
        max-width: none;
    }
}

.blog-tile-component__tile-category,
.blog-tile-component__tile-title {
    color: white;
    font-family: $heading-pro-double-regular;
    font-size: 16px;
    line-height: 130%;
    text-transform: none;
    font-weight: 300;
    margin: 0;
}

.blog-tile-component__tile-title {
    margin-top: 4px;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-tile-component__tile-category {
    font-family: $heading-pro-treble-extra-bold;
}

.blog-tile-component__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 28px;

    @include mq($not-mobile-tablet) {
        margin-top: 40px;
    }
}

.blog-tile-component__button {
    &.btn--outlined {
        border-width: 2px;
    }

    @include mq($mobile-pt-l) {
        width: 100%;
    }
}
